import { useMutation } from "@tanstack/react-query";
import supabase from "../supabase";
import { toast } from "react-toastify";
import useStore from "../../store/store";

const saveGameSession = async (gameSession) => {
  let score = 100;
  const { data: insertData, error: insertError } = await supabase
    .from("player_session")
    .insert([
      {
        phone_number: gameSession.phone_number,
        email: gameSession.email,
        name: gameSession.name,
        birth_date: gameSession.birth_date,
        gender: gameSession.gender,
        game_duration: gameSession.duration,
        score: score,
        last_name: gameSession.last_name,
      },
    ]);
  if (insertError) {
    throw insertError;
  }
  return insertData;
};

export const useSaveGame = () => {
  const { player, inGameTimer, gameWon } = useStore();
  // let gameSession = {
  //   phone_number: player.phone_number,
  //   email: player.email,
  //   name: player.name,
  //   last_name: player.last_name,
  //   birth_date: player.birth_date,
  //   duration: 90 - inGameTimer,
  //   gender: player.gender,
  // };
  return useMutation(() => saveGameSession({
    phone_number: player ? player.phone_number : null,
    email: player ? player.email : null,
    name: player ? player.name : null,
    last_name: player ? player.last_name : null,
    birth_date: player ? player.birth_date : null,
    duration: player ? 90 - inGameTimer : null,
    gender: player ? player.gender : null,
  }), {
    enabled: player && gameWon,
    onSuccess: (res) => {
      // console.log("onSuccess", res);
    },
    onError: (err) => {
      // console.log("onError", err.response.data.message);
      toast(err.response.data.message || "Something went wrong", {
        type: "error",
      });
    },
  });
};
