import React, { useState, useEffect, useRef } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import useStore from "../../store/store";
import { FiRepeat } from "react-icons/fi";
import Button from "../../components/Button";
import logo_white from "../../assets/logo_avene_light.png";
import face_error from "../../assets/face_error.png";
import face_ok from "../../assets/face_ok.png";
import selfie from "../../assets/selfie.png";
import { getMainZones } from "../../utils";

const StepFace = ({ model }) => {
  const {
    picData,
    setStartTaking,
    nextStep,
    modal,
    startTaking,
    capturedFace,
  } = useStore();
  const imagePic = useRef(null);
  const canvRef = useRef(null);
  const [cameraPos, setCameraPos] = useState(FACING_MODES.USER);

  const handleTakePhoto = (dataUri) => {
    useStore.setState({
      picData: dataUri,
      startTaking: false,
    });
  };

  const validImage = () => {
    // check if there is a face
    const imgVirtual = new Image();
    imgVirtual.src = picData;
    model.detect(imgVirtual).then((predictions) => {
      // console.log(predictions);
      const isFaceDetected = predictions.some((elem) => elem.label === "face");
      if (isFaceDetected) {
        useStore.setState({
          capturedFace: true,
          setPicData: picData,
          predictions: predictions,
          mainZones: getMainZones({
            x: predictions[0].bbox[0],
            y: predictions[0].bbox[1],
            w: predictions[0].bbox[2],
            h: predictions[0].bbox[3],
          }),
          modal: {
            show: true,
            state: "ok",
          },
        });
      } else {
        useStore.setState({
          modal: {
            show: true,
            state: "error",
          },
        });
        // removePicData();
      }
    });
  };

  return (
    <div className="flex flex-col justify-between content-center grow h-full light-overlay absolute top-0 w-full">
      {/* SHOW section */}
      <div className="flex flex-col h-full">
        {!startTaking && !capturedFace && !picData && (
          <div className="flex flex-row justify-center mt-9 pt-9">
            <img src={logo_white} alt="logo avene" />
          </div>
        )}
        {startTaking && (
          <div className="h-full flex flex-col justify-center grow overflow-x-hidden top-0 w-full">
            <Camera
              idealFacingMode={cameraPos}
              imageCompression={1}
              // isMaxResolution={true}
              isImageMirror={true}
              // idealResolution = {{height: '100%'}}
              // isFullscreen={true}
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri);
              }}
            />
          </div>
        )}
        {picData && (
          <img
            src={picData}
            alt="loading..."
            ref={imagePic}
            // height="100%"
            // width={640}
            className=" mx-auto h-full max-w-max"
          />
        )}
      </div>
      {/* Control section  */}
      <div className="bg-primary py-9 px-6 items-end">
        {!startTaking && !capturedFace && !picData && (
          <div className="flex flex-col">
            <p className="text-white text-3xl font-bold">PRENEZ</p>
            <div className="flex flex-row">
              <img src={selfie} alt="selfie" />
            </div>
            <p className="text-white leading-5 my-4">
              Le plus pratique c'est d'éloigner la 
              caméra et d'essayer de prendre une photo ou tout le visage est visible !
            </p>
            <div className="flex flex-row justify-center">
              <Button
                type="secondary"
                className="drop-shadow-lg"
                onClick={() => setStartTaking(true)}
                >
                PRENEZ UN SELFIE
            </Button>
            </div>
          </div>
        )}
        {startTaking && (
          <div className="flex flex-row justify-center">
            <Button
              type="secondary"
              className="drop-shadow-lg rounded-full align-middle content-center flex flex-row justify-center"
              onClick={() =>
                setCameraPos(
                  cameraPos === FACING_MODES.ENVIRONMENT
                    ? FACING_MODES.USER
                    : FACING_MODES.ENVIRONMENT
                )
              }
            >
              <span className="my-auto mr-2">Retournez l'appareil</span>{" "}
              <FiRepeat className="my-auto" />
            </Button>
          </div>
        )}
        {picData && (
          <div className="flex flex-row content-center justify-center gap-3">
            {((modal.show && modal.state === "error") || !modal.show) && (
              <Button
                type="secondary"
                className={`${modal.show ? "super-z" : ""}`}
                onClick={() => {
                  useStore.setState({
                    startTaking: true,
                    picData: null,
                    modal: {
                      show: false,
                      state: "",
                    },
                  });
                }}
              >
                REPRENDRE
              </Button>
            )}
            {!modal.show && (
              <Button className=" bg-green-400" onClick={validImage}>
                Validez
              </Button>
            )}
            {modal.show && modal.state === "ok" && capturedFace && (
              <Button
                className=" bg-green-400 super-z"
                onClick={() => {
                  model.dispose(); // resource clean up
                  nextStep();
                }}
              >
                SUIVANT
              </Button>
            )}
          </div>
        )}
      </div>
      {/* Main modal */}
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden={!modal.show}
        className={` ${
          !modal.show ? "hidden" : ""
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full`}
      >
        <div className="relative p-4 w-full flex flex-col justify-center max-w-2xl h-full md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-3xl shadow dark:bg-gray-700">
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              {modal.state === "ok" ? (
                <div className="flex flex-col justify-center align-middle">
                  <div className="flex flex-row justify-center">
                    <img className="mx-auto" src={face_ok} alt="ok_face" />
                  </div>
                  <p className="text-center text-xl font-bold mt-3">
                    VISAGE DÉTECTÉ
                  </p>
                </div>
              ) : (
                <div className="flex flex-col justify-center align-middle">
                  <div className="flex flex-row justify-center">
                    <img src={face_error} alt="error_face" />
                  </div>
                  <p className="text-center text-xl font-bold mt-3">
                    VISAGE NON DÉTECTÉ
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFace;

// {startTaking && !picData && (
//   <>
//     <Camera
//       idealFacingMode={cameraPos}
//       imageCompression={0.97}
//       isImageMirror={false}
//       onTakePhoto={(dataUri) => {
//         handleTakePhoto(dataUri);
//       }}
//     />
//     <div className="flex flex-row justify-center">
//       <button
//         className=" bg-orange-400 rounded-lg px-4 py-3 text-base font-bold my-3"
//         onClick={() =>
//           setCameraPos(
//             cameraPos === FACING_MODES.ENVIRONMENT
//               ? FACING_MODES.USER
//               : FACING_MODES.ENVIRONMENT
//           )
//         }
//       >
//         {" "}
//         flip camera
//       </button>
//     </div>
//   </>
// )}

// {/* <canvas ref={canvRef} /> */}
// {picData && !capturedFace && (
//   <>
//     <img src={picData} ref={imagePic} width={640} className=" mx-auto" />
//     <div className="flex flex-row content-center justify-center">
//       <button
//         className=" bg-red-500 rounded-lg px-4 py-3 text-base font-bold my-3"
//         onClick={() => {
//           setStartTaking(true);
//           setPicData(null);
//         }}
//       >
//         Retake
//       </button>
//       <button
//         className=" bg-green-500 rounded-lg px-4 py-3 text-base font-bold my-3"
//         onClick={validImage}
//       >
//         Valid Pictures
//       </button>
//     </div>
//   </>
// )}
// {capturedFace && (
//   <>
//     <canvas ref={canvRef} className="mx-auto w-full" />
//     <div className="flex flex-row content-center justify-center">
//       <button
//         className=" bg-blue-400 rounded-lg py-3 px-4 my-3"
//         onClick={() => goNextStep()}
//       >
//         Lets cream it
//       </button>
//     </div>
//   </>
// )}
