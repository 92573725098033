import React, { ReactNode } from "react";

// for documentation purposes

// interface Props {
//   type?: "primary" | "secondary" | "text";
//   children: ReactNode;
//   onClick?: () => void;
//   isBlock?: boolean;
//   disabled?: boolean;
//   className?: string;
//   href?: string;
//   target?: string;
//   width?: string;
// }

const textSize = "text-base lg:text-lg";

const padding = "px-7 lg:px-10 py-3 lg:py-4";

const color = {
  primary: "text-white",
  secondary: "text-primary dark:text-slate-200",
  text: "text-slate-700 hover:text-white dark:text-slate-200 dark:hover:text-white",
};

const backgroundColors = {
  primary: "bg-primary",
  secondary: "bg-white",
  text: "bg-light-button hover:bg-primary dark:bg-gray-800 dark:hover:bg-primary",
};

const border = {
  primary: "border-none",
  secondary: "dark:border-white",
  text: "border-none",
};

const Button = ({
  type = "primary",
  children,
  onClick,
  className = "",
  disabled = false,
  href,
  target,
  isBlock = true,
  width,
  role,
  loading,
}) => {
  const disabledStyle = disabled
    ? "opacity-50 cursor-not-allowed"
    : "transition ease-in-out duration-300 hover:cursor-pointer";

  let baseClasses = [
    "rounded-full",
    "uppercase",
    "font-oswald",
    textSize,
    border[type],
    backgroundColors[type],
    color[type],
    padding,
    disabledStyle,
  ];
  if (className) {
    baseClasses = [...baseClasses, ...className.split(" ")];
  }
  if (isBlock) {
    baseClasses = [...baseClasses, "block w-full"];
  }
  if (!!width) {
    baseClasses = [...baseClasses, width];
  }

  if (href) {
    let linkClasses = [
      ...baseClasses,
      "flex items-center justify-center whitespace-nowrap",
    ];
    return (
      <a
        to={href}
        target={target}
        onClick={onClick}
        className={linkClasses.join(" ")}
      >
        {loading ? '...loading' : children}
      </a>
    );
  }

  return (
    <button
      type={role || "button"}
      onClick={onClick}
      className={baseClasses.join(" ")}
      disabled={disabled}
    >
      {loading===true ? '...loading' : children}
    </button>
  );
};

export default Button;
