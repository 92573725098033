import React, { useEffect } from "react";
import useStore from "../../store/store";
import "./End.scss";
import ops from "../../assets/ops.png";
import bravo from "../../assets/bravo.png";
import { useNavigate } from "react-router-dom";
import { useSaveGame } from "../../services/api/useSaveGame";
import Button from "../../components/Button";

function End() {
  const { gameFinished, gameWon, gameLose, player, saved, setSaved, restartGame } =
    useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (!player || !gameFinished) {
      navigate("/");
    }
  }, [player, gameFinished, gameWon, gameLose])
  
  const { mutateAsync } = useSaveGame();
  
  

  useEffect(() => {
    const saveGame = async () => {
      await mutateAsync();
    };
    if (gameFinished && gameWon && player && !saved) {
      saveGame().catch((err) => console.log(err));
      setSaved(true);
    }
  });

  const handleResetGame = () => {
    restartGame();
    navigate("/game");
  }
  return (
    <div
      className={`h-full w-full flex flex-col justify-end ${
        gameWon ? "bg-win" : "bg-lose"
      }`}
    >
      <div className="bg-primary rounded-t-3xl pb-7 px-8 pt-4">
        <div className="flex flex-row justify-start mb-4">
          <img src={gameWon ? bravo : ops} alt="" />
        </div>

        {gameWon ? (
          <p className="text-2xl font-extrabold text-left uppercase text-white">
            Vous êtes selectionné pour le tirage au sort!
          </p>
        ) : (
          <div className="flex flex-row">
            <Button type="secondary" onClick={() => handleResetGame()}>ESSAYER DE NOUVEAU.</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default End;
