import React from "react";

function Select({
  options,
  name,
  placeholder,
  errors,
  label,
  register,
  value = null,
  noLeftBorder = false,
  noRightBorder = false,
  ...rest
}) {
  return (
    <div className="flex flex-col mr-2">
      <label
        className="flex text-white font-semibold font-poppins drop-shadow-sm shadow-black mb-2 pl-1"
        htmlFor={name}
      >
        {label ?? `.`}
      </label>
      <select
        id={name}
        name={name}
        // value={value !== null ? value : ""}
        style={{
          minWidth: "110px",
        }}
        className={
          "border outline-none rounded-full text-primary text-sm p-3 px-4 min-w-min w-full mb-2 font-poppins" +
          +(noLeftBorder ? " rounded-l-none" : "") +
          (noRightBorder ? " rounded-r-none" : "")
        }
        {...register(name)}
        {...rest}
      >
        {options?.map((x) => (
          <option key={`op_${x.value}`} value={x.value}>
            {x.label}
          </option>
        ))}
      </select>
      {errors && errors[name] && (
        <span className=" text-sm text-red-500">{errors[name].message}</span>
      )}
    </div>
  );
}

export default Select;
