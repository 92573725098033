import React, { useState } from "react";
import CreamApplay from "./CreamApplay";
import StepFace from "./FaceCapture";
import useStore from "../../store/store";
import "./GameStepper.scss";
import { useNavigate } from "react-router-dom";
import DemoStep from "./DemoStep";
import Button from "../../components/Button";

function Stepper({ model, detector }) {
  const { activeStep, player, gameFinished, demoDone, setDemoDone, demoLoaded, timerStarted, tagDone, setTagDone  } = useStore();
  const navigate = useNavigate()
  if(!player) {
    useStore.setState({
      activeStep: 0,
    })
    navigate("/")
  }

  if(gameFinished) {
    navigate("/end")
  }

  return (
    <div className="relative flex flex-col h-full w-full max-h-full stepper-bg">
      <div className={`drop-shadow-xl bg-primary ${!timerStarted ? 'rounded-b-3xl' : ''} super-z`}>
        {demoDone && <h4 className="uppercase w-full text-white py-4 text-center font-bold text-lg">
          étape {activeStep + 2}
        </h4>}
        {!tagDone && <div className="uppercase  text-white px-9 py-4 text-center font-bold text-lg">
         <Button type="secondary" onClick={() => setTagDone(true)} disabled={!demoLoaded}>{demoLoaded ? 'à la démo' : 'la démo est en cours de chargement'}</Button>
        </div>}
        {tagDone && !demoDone && <div className="uppercase  text-white px-9 py-4 text-center font-bold text-lg">
         <Button type="secondary" onClick={() => setDemoDone(true)} disabled={!demoLoaded}>{demoLoaded ? 'je comprends' : 'la démo est en cours de chargement'}</Button>
        </div>}
      </div>
      <div className="flex flex-col justify-center content-center flex-grow w-full">
        {!demoDone && (
          <DemoStep />
        )}
        {demoDone && activeStep === 0 && (
          <StepFace model={model} />
        )}
        {demoDone && activeStep === 1 && <CreamApplay />}
      </div>
    </div>
  );
}

export default Stepper;
