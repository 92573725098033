import { useQuery } from "@tanstack/react-query";
import supabase from "../supabase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/store";

const checkPlayer = async (email, phoneNumber) => {
  // console.log('called', email, phoneNumber)
  const { data } = await supabase
    .from("player_session")
    .select("id")
    .or(`email.eq.${email},phone_number.eq.${phoneNumber}`)
    .limit(1)
  return data.length === 0;
};

export const useCheckPlayer = (player, valide) => {
  const navigation = useNavigate()
  const {setPlayer} = useStore()
  const {email , phone_number} = player;
  // console.log("useCheckPlayer", valide);
  return useQuery([`player_check`, email, phone_number], ({queryKey}) => checkPlayer(queryKey[1], queryKey[2]), {
    enabled: !!valide && valide,
    onSuccess: (res) => {
      // console.log("onSuccess", res);
      if(res === false) {
        toast.error("Player already exists");
      } else {
        setPlayer(player)
        navigation("/game");
      }
    },
    onError: (err) => {
      // console.log("onError", err.response.data.message);
      toast(err.response.data.message || "Something went wrong", {
        type: "error",
      });
    },
  });
};
