import React, { useState } from "react";
import logo from "../../assets/logo_avene_color.png";
import skin_protect_logo from "../../assets/logo_skin_protect_ocean_respect.png";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "../../components/Select";
import "./PlayerRegister.scss";
import { useCheckPlayer } from "../../services/api/useCheckPlayer";

export const registerSchema = yup.object().shape({
  name: yup.string().required("Préom requis"),
  last_name: yup.string().required("Nom requis"),
  email: yup.string().email().required("Email est requis"),
  phone_number: yup
    .number("Phone number is invalid")
    .required("Phone number is required")
    .min(20000000, "Phone number is invalid")
    .max(99999999, "Phone number is invalid")
    .typeError('Phone number format "20123123"'),
  birth_date: yup
    .date()
    .required("Birth date is required")
    .typeError("date de naissance est invalide"),
  gender: yup.string().oneOf(["F", "M"]),
});

function PlayerRegister() {
  
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(registerSchema),
    criteriaMode: "firstError",
    mode: "onChange",
  });
  const [checked, setChecked] = useState(false);
  const [doCall, setDoCall] = useState(false)
  const {isLoading, isStale} = useCheckPlayer(getValues(), (isValid && checked && doCall));
  const onSubmit = async (data) => {
    setDoCall(true)
  };

  return (
    <div className="h-full w-full bg-primary main-section-register">
      <div className="relative p-5 min-h-full bg-opacity-10 pb-8 reverted-form flex flex-col justify-center">
        <img
          className="w-4/5 md:w-1/2 mx-auto mb-5"
          src={logo}
          alt="logo avene"
        />
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
          <div className="flex flex-row">
            <Input
              errors={errors}
              placeholder="Nom"
              label="Nom/ Prénom"
              noRightBorder
              id="last_name"
              register={register}
              name="last_name"
              // className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            <Input
              errors={errors}
              placeholder="Prénom"
              // label="Username"
              id="name"
              noLeftBorder
              register={register}
              name="name"
            />
          </div>
          <Input
            errors={errors}
            placeholder="example@email.com"
            label="Adresse Mail"
            id="email"
            type="email"
            register={register}
            name="email"
          />
          <Input
            errors={errors}
            placeholder="numero de téléphone"
            label="Numéero de téléphone"
            id="phone_number"
            type="number"
            register={register}
            name="phone_number"
          />
          <div className="flex flex-row gap-3">
            <Select
              name="gender"
              register={register}
              label="Sexe"
              errors={errors}
              options={[
                { label: "Femme", value: "F" },
                { label: "Male", value: "M" },
              ]}
            />
            <div className="px-2"></div>
            <Input
              errors={errors}
              placeholder="Date de naissance"
              label="Date de Naissance"
              id="birth_date"
              type="date"
              register={register}
              name="birth_date"
            />
          </div>
          <div className="flex items-center mt-2 mb-4">
            <input
              required
              id="checked-checkbox"
              type="checkbox"
              onChange={(e) => setChecked(e.currentTarget.checked)}
              className="w-4 h-4 outline-none text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="checked-checkbox"
              className="ml-2 text-xs font-medium text-white dark:text-gray-300"
            >
              J'ai lu et j'accept les conditions générales d'utilisation <a href="reglement.pdf" className="text-blue-500">ici</a>
            </label>
          </div>
          <Button role="submit" disabled={!checked}>
            { isLoading && !isStale ? 'LOADING...' : 'VALIDER'}
          </Button>
        </form>
        <div className="flex flex-row justify-center items-center mt-4">
          <img className="w-2/3 md:w-1/3" src={skin_protect_logo} alt="" />
        </div>
      </div>
    </div>
  );
}

export default PlayerRegister;
