import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import { useNavigate } from "react-router";
import logo from "../../assets/logo_avene_light.png";
import Button from "../../components/Button";
import "./Home.scss";

const BoxStyle =
  "bg-secondary rounded-3xl py-6 px-6 mb-2 mt-2 text-text-primary font-poppins text-sm shadow-md";

const BoxStyleBtn =
  "bg-secondary rounded-3xl py-6 px-10 mb-2 mt-2 text-text-primary font-poppins text-sm shadow-xl cursor-pointer";

function Home() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className="h-full w-full bg-primary"
      style={{
        backgroundImage: `url(${require("../../assets/sea.jpeg")})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className={`absolute top-0 left-0 h-full w-full overlay`}></div>
      <div className="relative p-5 py-20 h-full bg-opacity-10 reverted flex flex-col justify-between">
        <img className="w-4/5 md:w-1/2 mx-auto" src={logo} alt="logo avene" />
        <Button onClick={() => setOpen(true)} type="secondary">
          Commencez le jeu
        </Button>
      </div>
      <Sheet
        isOpen={open}
        onClose={() => setOpen(false)}
        snapPoints={[0.55]}
        disableDrag
      >
        <Sheet.Container className="radius-lg">
          <Sheet.Header />
          <Sheet.Content>
            <div className="px-3">
              <p className="text-white font-bold font-poppins text-sm">
                Protegez votre peau avec les soins solaires Eau Thermale Avène et
                tentez de remporter vos indisponsables de cet été!
              </p>
              <div className={BoxStyle}>
                <p>
                  <span className="font-semibold uppercase">étape 1:</span>{" "}
                  Remplir le formulaire*
                </p>
              </div>
              <div className={BoxStyle}>
                <p>
                  <span className="font-semibold uppercase">étape 2:</span>{" "}
                  Prenez un selfie
                </p>
              </div>
              <div className={BoxStyle}>
                <p>
                  <span className="font-semibold uppercase">
                    étape 3:
                  </span>{" "}
                  appliquez votre crème solaire en suivant le sens des flèches
                </p>
              </div>
              <div className="flex flex-row justify-center">
                <div
                  className={BoxStyleBtn}
                  onClick={() => navigate("/register")}
                >
                  <p className="text-center mx-7">
                    <span className="font-semibold uppercase text-primary">
                      JE FONCE
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
}

export default Home;
