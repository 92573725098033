import { InputHTMLAttributes } from 'react';

// for documentation see:

// export interface Props<T = unknown>
//   extends InputHTMLAttributes<HTMLInputElement> {
//   name: string;
//   label?: string;
//   disabled?: boolean;
//   errors?: FieldErrors;
//   register: UseFormRegister<T>;
// }

const Input = ({
  disabled = false,
  placeholder,
  errors,
  label,
  name,
  value,
  noLeftBorder = false,
  noRightBorder = false,
  register,
  ...rest
}) => {
  return (
    <div className="flex flex-col">
      <label className="flex text-white font-semibold font-poppins drop-shadow-sm shadow-black mb-2 pl-1" htmlFor={name}>
        {label ?? `.`}
      </label>
      <input
        className={"border outline-none rounded-full text-gray-500 text-sm p-3 px-4 w-full mb-2 font-poppins" + (noLeftBorder ? ' rounded-l-none' : '') + (noRightBorder ? ' rounded-r-none' : '')}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        {...register(name)}
        {...rest}
      />
      {errors && errors[name] && (
        <span className=" text-sm text-red-500">{errors[name].message}</span>
      )}
    </div>
  );
};

export default Input;