import useStore, { addToHittedZone, addToSpecificZone } from "./store/store";
import hand from "./assets/goute.png";

const imgHand = new Image();
imgHand.src = hand;
// position in the input.
const ANCHOR_POINTS = [
  [0, 0, 0],
  [0, 0.1, 0],
  [-0.1, 0, 0],
  [-0.1, -0.1, 0],
];

const fingerLookupIndices = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
}; // for rendering each finger as a polyline

const connections = [
  [0, 1],
  [1, 2],
  [2, 3],
  [3, 4],
  [0, 5],
  [5, 6],
  [6, 7],
  [7, 8],
  [0, 9],
  [9, 10],
  [10, 11],
  [11, 12],
  [0, 13],
  [13, 14],
  [14, 15],
  [15, 16],
  [0, 17],
  [17, 18],
  [18, 19],
  [19, 20],
];

// Points for fingers
const fingerJoints = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
};

// Infinity Gauntlet Style
const style = {
  0: { color: "yellow", size: 15 },
  1: { color: "gold", size: 6 },
  2: { color: "green", size: 10 },
  3: { color: "gold", size: 6 },
  4: { color: "gold", size: 6 },
  5: { color: "purple", size: 10 },
  6: { color: "gold", size: 6 },
  7: { color: "gold", size: 6 },
  8: { color: "gold", size: 6 },
  9: { color: "blue", size: 10 },
  10: { color: "gold", size: 6 },
  11: { color: "gold", size: 6 },
  12: { color: "gold", size: 6 },
  13: { color: "red", size: 10 },
  14: { color: "gold", size: 6 },
  15: { color: "gold", size: 6 },
  16: { color: "gold", size: 6 },
  17: { color: "orange", size: 10 },
  18: { color: "gold", size: 6 },
  19: { color: "gold", size: 6 },
  20: { color: "gold", size: 6 },
};

/**
 * Draw the keypoints on the video.
 * @param keypoints A list of keypoints.
 * @param handedness Label of hand (either Left or Right).
 */
function drawKeypoints(keypoints, handedness, rect, ctx) {
  const keypointsArray = keypoints;
  ctx.fillStyle = handedness === "Left" ? "Red" : "Blue";
  ctx.strokeStyle = "White";
  ctx.lineWidth = 2;

  for (let i = 0; i < keypointsArray.length; i++) {
    const y = keypointsArray[i].x;
    const x = keypointsArray[i].y;
    drawPoint(x - 2, y - 2, 3, ctx, i, rect);
  }

  // const reactForHand = handRectArea(keypointsArray);
  
  // switch to goute instead of hand
  ctx.drawImage(
    imgHand,
    keypointsArray[8].x - 10,
    keypointsArray[8].y - 10,
    20,
    20
  );

  // const fingers = Object.keys(fingerLookupIndices);
  // for (let i = 0; i < fingers.length; i++) {
  //   const finger = fingers[i];
  //   const points = fingerLookupIndices[finger].map((idx) => keypoints[idx]);
  //   drawPath(points, false, ctx);
  // }
}

function drawPath(points, closePath, ctx) {
  const region = new Path2D();
  ctx.lineWidth = 5;
  region.moveTo(points[0].x, points[0].y);
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    region.lineTo(point.x, point.y);
  }

  if (closePath) {
    region.closePath();
  }
  ctx.stroke(region);
}

function drawPoint(y, x, r, ctx, pos, rect) {
  const zones = useStore.getState().mainZones;
  // let point_style = style[pos];
  if (pos === 8) {
    // we track the INDEX TIP
    zones.forEach((element) => {
      if (contains(x, y, element.x, element.y, element.w, element.h)) {
        // console.log("TIP is inside ", element.key);
        // add those x?y to that zone state 
        // store will call function to find out if it's in good direction and length
        // if yes , redraw on the 2s canvas
        // addToHittedZone(element.key);
        addToSpecificZone(element.key, x, y);
      }
    });
  }
  // not doing the actual drawing here, but just adding to the list of zones
  // ctx.beginPath();
  // ctx.arc(x, y, point_style.size, 0, 2 * Math.PI);
  // ctx.fillStyle = point_style.color;
  // ctx.fill();
}

/**
 * Draw the keypoints on the video.
 * @param hand A hand with keypoints to render.
 * @param rect the face rect {x,y,w,h}
 * @param ctx canvas context 2D
 */
export function drawResult(hand, rect, ctx) {
  if (!hand || hand.length === 0) {
    return;
  }
  if (hand[0].keypoints != null) {
    drawKeypoints(hand[0].keypoints, hand.handedness, rect, ctx);
  }
}

export function contains(Px, Py, Rx, Ry, Rw, Rh) {
  return Rx <= Px && Px <= Rx + Rw && Ry <= Py && Py <= Ry + Rh;
}

export function getMainZones(rect) {
  const zoneTop = {
    x: rect.x + rect.w / 6,
    y: rect.y + rect.h / 6,
    w: (rect.w / 6) * 4,
    h: 50,
    key: "top",
  };

  const zoneBottom = {
    x: rect.x + rect.w / 4,
    y: rect.y + rect.h - 20,
    w: (rect.w / 4) * 2,
    h: 35,
    key: "bottom",
  };

  const zoneRight = {
    x: rect.x + rect.w / 2 + rect.w / 6,
    y: rect.y + rect.h / 2,
    w: rect.w / 3,
    h: rect.h / 4,
    key: "right",
  };

  const zoneLeft = {
    x: rect.x,
    y: rect.y + rect.h / 2,
    w: rect.w / 3,
    h: rect.h / 4,
    key: "left",
  };

  return [zoneTop, zoneBottom, zoneLeft, zoneRight];
}

export async function cacheImage(imageArray, doneLoadingCallback) {
  const promises = await imageArray.map((image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (err) => {
        reject(err);
      };
    });
  });
  await Promise.all(promises);
  doneLoadingCallback();
}

const handRectArea = (keypoints) => {
  let min_x = keypoints[0].x;
  let min_y = keypoints[0].y;
  let max_x = keypoints[0].x;
  let max_y = keypoints[0].y;

  for (let i = 1; i < keypoints.length; i++) {
    const point = keypoints[i];
    if (point.x < min_x) {
      min_x = point.x;
    }
    if (point.y < min_y) {
      min_y = point.y;
    }
    if (point.x > max_x) {
      max_x = point.x;
    }
    if (point.y > max_y) {
      max_y = point.y;
    }
  }

  return {
    x: min_x,
    y: min_y,
    w: max_x,
    h: max_y,
  };
};
