import React,{useRef, useEffect} from "react";
import gif from "../../assets/animation_new.gif";
import useStore from "../../store/store";
import tag from "../../assets/tag_skin_protect.png";
import logo_protect from "../../assets/logo_skin_protect_ocean_respect.png";

function DemoStep() {
  const { setDemoLoaded, tagDone, setTagDone } = useStore();
  const demo = useRef(null)

  useEffect(() => {
      const img = new Image();
      img.onload = function() {
        console.info('loaded demo')
        setDemoLoaded(true);
      }
      img.src = gif;
  }, [])

  return (
    <>
    {tagDone ? (
      <div className="flex flex-col h-full absolute top-0">
        <img ref={demo} src={gif} alt="Loading ..." />
        </div>
      ) : (
        <div className="flex flex-col justify-between content-center grow h-full light-overlay absolute top-0 w-full tag-bg" id="zoneTag">
          <div className="flex flex-col h-full">
            <div className="flex flex-row justify-center mt-7">
            </div>
          </div>
          <div className="rounded-t-3xl bg-primary py-9 px-6 flex flex-col flex-grow justify-center gap-1">
          <div className="flex flex-row opacity-30">
            <img src={tag} alt="tag #skin protect" />
           </div>
          <div className="flex flex-row opacity-50">
            <img src={tag} alt="tag #skin protect" />
           </div> 
           <div className="flex flex-row">
            <img src={tag} alt="tag #skin protect" />
           </div>
           <div className="flex flex-row justify-center mt-5">
            <img src={logo_protect} className="w-60" alt="" />
           </div>
          </div>
        </div>
      )}
      </>
  );
}

export default DemoStep;
