import create from "zustand";
import { useSaveGame } from "../services/api/useSaveGame";

const useStore = create((set, get) => ({
  ready: false,
  picData: null,
  prediction: [],
  hittedZone: [],
  gameFinished: false,
  gameWon: false,
  gameLose: false,
  activeStep: 0,
  demoDone: false,
  tagDone: false,
  demoLoaded: false,
  model: null,
  inGameTimer: 90,
  timerStarted: false,
  detector: null,
  player: null,
  modal: {
    show: false,
    state: "",
  },
  capturedFace: false,
  startTaking: false,
  mainZones: [],
  saved: false, // game session saved

  // related to game logic
  topZone: [],
  bottomZone: [],
  leftZone: [],
  rightZone: [],
  topZoneOk: false,
  bottomZoneOk: false,
  leftZoneOk: false,
  rightZoneOk: false,
  topZoneError: false,
  bottomZoneError: false,
  leftZoneError: false,
  rightZoneError: false,
  topZoneChecked: false,
  bottomZoneChecked: false,
  leftZoneChecked: false,
  rightZoneChecked: false,

  // setters & getters
  setDemoLoaded: (demoLoaded) => set((state) => ({ demoLoaded: demoLoaded })),
  setDemoDone: (demoDone) => set((state) => ({ demoDone: demoDone })),
  setTagDone: (tagDone) => set((state) => ({ tagDone: tagDone })),
  setReady: (ready) => set((state) => ({ ready })),
  setStartTaking: (startTaking) => set({ startTaking }),
  setModel: (model) => set({ model: model }),
  setDetector: (detector) => set({ detector: detector }),
  setPlayer: (player) => set({ player: player }),
  nextStep: () => set((state) => ({ activeStep: state.activeStep + 1 })),
  setPicData: (picData) => set({ picData: picData }),
  removePicData: () => set({ picData: null }),
  restartGame: () => set({
    gameFinished: false,
    gameWon: false,
    gameLose: false,
    activeStep: 0,
    topZone: [],
    bottomZone: [],
    leftZone: [],
    rightZone: [],
    topZoneOk: false,
    bottomZoneOk: false,
    leftZoneOk: false,
    rightZoneOk: false,
    topZoneError: false,
    bottomZoneError: false,
    leftZoneError: false,
    rightZoneError: false,
    topZoneChecked: false,
    bottomZoneChecked: false,
    leftZoneChecked: false,
    rightZoneChecked: false,
    timerStarted: false,
  }),
  addToSpecificZone: (zone, x, y) =>
    set((state) => {
      // win condition 
      if(state.topZoneOk && state.bottomZoneOk && state.leftZoneOk && state.rightZoneOk) {
        set({ gameFinished: true, gameWon: true });
        return;
      }
      // rest of game logic
      if (
        (zone === "top" && state.topZoneOk) ||
        (zone === "bottom" && state.bottomZoneOk) ||
        (zone === "left" && state.leftZoneOk) ||
        (zone === "right" && state.rightZoneOk)
      ) {
        return;
      }

      const _zone =
        zone === "top"
          ? state.topZone
          : zone === "bottom"
          ? state.bottomZone
          : zone === "left"
          ? state.leftZone
          : state.rightZone;
      _zone.push({ x, y });
      // console.log("_zone", _zone);
      // at least 8 points to be considered a zone
      if(_zone.length < 8) {
        set((state) => {
          if (zone === "top") {
            return { topZone: _zone };
          } else if (zone === "bottom") {
            return { bottomZone: _zone };
          } else if (zone === "left") {
            return { leftZone: _zone };
          } else if (zone === "right") {
            return { rightZone: _zone };
          }
        })
        return;
      }
      // check on the zone if it's ok
      // find min y and max y
      let max_y = Math.max(..._zone.map((o) => o.y));
      let min_y = Math.min(..._zone.map((o) => o.y));
      // console.log("min_y", min_y);
      // console.log("max_y", max_y);
      // find min x and max x
      let max_x = Math.max(..._zone.map((o) => o.x));
      let min_x = Math.min(..._zone.map((o) => o.x));
      // console.log("min_x", min_x);
      // console.log("max_x", max_x);
      // diff min y and max y should be less than 40 / for left and right zones it should be less than 90
      let good_line = ['top', 'bottom'].includes(zone) ? max_y - min_y < 40 : max_y - min_y < 90;
      // console.log('good_line', good_line);
      // diff min x and max x should be equal to the width of the zone
      const width_factor = ['left', 'right'].includes(zone) ? 0.4 : 0.5;
      let target_width = state.mainZones.filter((o) => o.key === zone)[0].w * width_factor;
      // console.log('target_width', target_width);
      let good_width =
        max_x - min_x >= target_width;
      // console.log('good_width', good_width);
      // if all conditions are ok, set the zone as ok
      if (good_line && good_width) {
        if (zone === "top") {
          // console.log("top zone ok");
          set((state) => ({
            topZoneOk: true,
            topZone: _zone,
            topZoneError: false,
          }));
          setTimeout(() => {
            set((state) => ({
              topZoneChecked: true,
            }));
          }, 1000);
        } else if (zone === "bottom") {
          // console.log("bottom zone ok");
          set((state) => ({
            bottomZoneOk: true,
            bottomZone: _zone,
            topZoneError: false,
          }));
          setTimeout(() => {
            set((state) => ({
              bottomZoneChecked: true,
            }));
          }, 1000);
        } else if (zone === "left") {
          // console.log("left zone ok");
          set((state) => ({
            leftZoneOk: true,
            leftZone: _zone,
            leftZoneError: false,
          }));
          setTimeout(() => {
            set((state) => ({
              leftZoneChecked: true,
            }));
          }, 1000);
        } else if (zone === "right") {
          // console.log("right zone ok");
          set((state) => ({
            rightZoneOk: true,
            rightZone: _zone,
            rightZoneError: false,
          }));
          setTimeout(() => {
            set((state) => ({
              rightZoneChecked: true,
            }));
          }, 1000);
        }
      } else {
        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
        navigator.vibrate(1000);
        if (zone === "top") {
          // console.log("top zone error");
          set((state) => ({
            topZoneOk: false,
            topZoneError: true,
            topZone: []
          }));
        } else if (zone === "bottom") {
          // console.log("bottom zone error");
          set((state) => ({
            bottomZoneOk: false,
            bottomZoneError: true,
            bottomZone: []
          }));
        } else if (zone === "left") {
          // console.log("left zone error");
          set((state) => ({
            leftZoneOk: false,
            leftZoneError: true,
            leftZone: []
          }));
        } else if (zone === "right") {
          // console.log("right zone error");
          set((state) => ({
            rightZoneOk: false,
            rightZoneError: true,
            rightZone: []
          }));
        }
      }
    }),
  addToHittedZone: (zone) => {
    let all = get().hittedZone;
    all.push(zone);
    // count 20 point for each zone
    let topHits = all.filter((elem) => elem === "top").length;
    let bottomHits = all.filter((elem) => elem === "bottom").length;
    let leftHits = all.filter((elem) => elem === "left").length;
    let rightHits = all.filter((elem) => elem === "right").length;
    if (
      topHits >= 20 &&
      bottomHits >= 20 &&
      leftHits >= 20 &&
      rightHits >= 20
    ) {
      set({ gameFinished: true, gameWon: true, hittedZone: all });
    } else {
      set({ hittedZone: all });
    }
  },
  setPicWithPrediction: (picData, predictions) =>
    set({ picData: picData, predictions: predictions }),
  setGameStatus: (game) =>
    set({ gameFinished: game.status, gameWon: game.won, gameLose: game.lose }),
  setInGameTimer: (timer) => set({ inGameTimer: timer }),
  setTimerStarted: (timer) => set({ timerStarted: timer }),
  setSaved: (saved) => set({ saved: saved }),
}));

export const addToHittedZone = (zone) =>
  useStore.getState().addToHittedZone(zone);
export const addToSpecificZone = (zone, x, y) => useStore.getState().addToSpecificZone(zone, x, y);
export const setGameStatus = (game) => useStore.getState().setGameStatus(game);
export const startTimer = () => {
  if (useStore.getState().timerStarted) {
    return;
  }

  let timer = setInterval(() => {
    let inGameTimer = useStore.getState().inGameTimer;
    if (inGameTimer > 0) {
      useStore.getState().setInGameTimer(inGameTimer - 1);
    } else {
      clearInterval(timer);
      if (!useStore.getState().gameFinished) {
        useStore
          .getState()
          .setGameStatus({ status: true, won: false, lose: true });
      }
    }
  }, 1000);
  if (!useStore.getState().timerStarted) {
    useStore.setState({
      timerStarted: true,
    });
  }
};
export default useStore;
